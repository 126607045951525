<template lang="pug">
  div
    div(:class="{disabledSection:!formData.constraints.weight}")
      label.cp.mb-5
        input(
          type="checkbox"
          :checked="enabled"
          @click="updateWeightConstraint"
        )
        .text-truncate Weight constraint by individual assets

    div(v-if="enabled && weightValues.length < 1")
      v-alert(text type="error") Add at least 1 constraint

    div(:class="{disabledSection: !enabled}")
      v-list.pt-0(dense)
        v-list-item(
          color="primary"
          @click="removeWeightItem(i)"
          :key="i"
          v-for="(item, i) in weightValues"
          :input-value="true"
        )
          v-list-item-icon.mr-4
            x-icon.blue--text.text--accent-4(size="1.5x")
          v-list-item-content
            v-list-item-title.d-flex
              span.mr-2.font-weight-regular.fs-14.colors--color16
                | [{{parseSymbol(item.symbol).code}}]
              span.ml-auto.pl-3.font-weight-regular.fs-12.colors--color14
                |  min:
                span.colors--color16.fs-14  {{Math.round(item.min * 100)}}%
                |  max:
                span.colors--color16.fs-14  {{Math.round(item.max * 100)}}%

      v-row
        v-col
          v-select(
            dense
            outlined
            :items="tickerOptions"
            v-model="symbol"
          )
        v-col
          v-text-field(
            dense
            outlined
            v-model.number="min"
            prefix="min :"
            suffix="%"
            type="number"
          )
        v-col
          v-text-field(
            dense
            outlined
            v-model.number="max"
            prefix="max :"
            suffix="%"
            type="number"
          )
        v-col(cols="auto")
          v-btn.white--text.float-right(
            color="green"
            @click="addConstraint()"
            :disabled="!validForm"
          )
            span.mt-1.fs-14 Add
            plus-icon
</template>

<script>
import { PlusIcon, DeleteIcon, XIcon } from 'vue-feather-icons'
import { mapMutations, mapGetters, mapState } from "vuex";
import { isValidConstraintNumber } from "../../../lib/utils.js";

export default {
  name: 'CreatePortfolioConstraintsWeight',

  components: {
    XIcon,
    PlusIcon,
    DeleteIcon
  },

  data() {
    return {
      symbol: null,
      min: 0,
      max: 0,
    }
  },

  computed: {
    ...mapState("OptimizationConfig", [
      "formData"
    ]),

    ...mapGetters("OptimizationConfig", [
      "activeConstraint"
    ]),

    tickerOptions() {
      const alreadyAdded = this.weightValues.map(i => i.symbol);
      const options = this.formData.tickers
        .filter(i => !alreadyAdded.includes(i.symbol))
        .map(i => {
          return {
            text: i.code,
            value: i.symbol
          }
        });
      options.unshift('All Assets')
      return options
    },

    weightValues() {
      return this.formData.constraints.weightConstraints.value;
    },

    enabled() {
      return this.formData.constraints.weightConstraints.enabled;
    },

    validForm() {
      return this.symbol
        && isValidConstraintNumber(this.max)
        && isValidConstraintNumber(this.min);
    },
  },

  methods: {
    ...mapMutations("OptimizationConfig", [
      "setEnabledConstraint",
      "addWeightItem",
      "removeWeightItem",
      "enableWeightConstraint",
      "disableWeightConstraint"
    ]),

    addConstraint() {
      if (this.symbol === 'All Assets') {
        this.tickerOptions.filter(e => e !== 'All Assets').forEach(e => {
          this.addWeightItem({
            symbol: e.value,
            min: this.min / 100,
            max: this.max / 100,
          });
        })
      } else {
        this.addWeightItem({
          symbol: this.symbol.toString(),
          min: this.min / 100,
          max: this.max / 100,
        });
      }

      this.symbol = null;
      this.min = 0;
      this.max = 0;
    },

    updateWeightConstraint(ev) {
      if (ev.target.checked) {
        this.enableWeightConstraint();
      } else {
        this.disableWeightConstraint();
      }
    }
  },
}
</script>