<template lang="pug">
  v-row(v-if="formData")
    v-col(cols="12" md="")
      v-select(
        outlined
        label="Train Period"
        full-width
        dense
        hide-details
        :items="items"
        v-model="trainPeriod"
      )
    v-col.pl-md-0(cols="3")
      create-portfolio-data-transfer
    v-col.pl-md-0(v-if="isCopyAvailable" cols="1")
      create-portfolio-data-copy
</template>

<script>
import CreatePortfolioDataTransfer from "@/components/create-portfolio/create-portfolio-data-transfer";
import CreatePortfolioDataCopy from "@/components/create-portfolio/create-portfolio-data-copy";

import { mapState, mapMutations } from "vuex";

export default {
  name: "CreatePortfolioSectionInfoDateselect",
  components: { CreatePortfolioDataTransfer, CreatePortfolioDataCopy },
  computed: {
    ...mapState("OptimizationConfig", ["formData"]),

    trainPeriod: {
      set(value) {
        this.setTrainPeriod(value);
      },
      get() {
        return this.formData.trainPeriod;
      },
    },
  },
  data() {
    return {
      items: [
        {
          text: "2 weeks",
          value: 14,
        },
        {
          text: "1 month",
          value: 30,
        },
        {
          text: "3 months",
          value: 90,
        },
        {
          text: "6 months",
          value: 180,
        },
        {
          text: "9 months",
          value: 270,
        },
        {
          text: "1 year",
          value: 365,
        },
        {
          text: "2 years",
          value: 730,
        },
      ],
      isCopyAvailable: process.env.VUE_APP_PROJECT_NAME === "Kuveyt Türk",
    };
  },

  methods: {
    ...mapMutations("OptimizationConfig", ["setTrainPeriod"]),
  },
};
</script>

<style lang="sass" scoped></style>
