<template lang="pug">
  v-row
    v-col(cols="12").d-flex.justify-sm-end
      v-btn(
        outlined :block="$vuetify.breakpoint.xs"
        @click="nextSection"
        color="#023CC4"
      )
        chevron-right-icon(size="1.5x")
        span.ml-1.font-weight-medium.fs-14
          | Sonraki
</template>

<script>
import {ChevronRightIcon} from 'vue-feather-icons'

export default {
  name: 'CreatePortfolioSectionTemplateFooter',
  components: {ChevronRightIcon},
  data() {
    return {test: false}
  },
  methods: {
    nextSection() {
      // todo
      //this.$emit('nextSection')
    }
  }
}
</script>

<style lang="sass" scoped></style>
