<template lang="pug">
  create-portfolio-section-template(
    title="Portfolio Details"
    :sectionIndex="sectionIndex"
  )
    v-row.align-center
      v-col(cols="12" md="12")
        v-row
          v-col(cols="12" md="4")
            .font-weight-regular.fs-15.black--text Entity Type
            v-col(cols="12")
              v-btn.mb-2(
                color="primary"
                block
                :outlined="selectedEntityType != 'real'"
                @click="setSelectedEntityType('real')"
                :disabled="saving"
              ) Real
              v-btn(
                color="primary"
                block
                :outlined="selectedEntityType != 'corporate'"
                @click="setSelectedEntityType('corporate')"
                :disabled="saving"
              ) Corporate

          v-col(cols="12" md="4")
            .font-weight-regular.fs-15.black--text Investor Type
            v-col(cols="12")
              v-btn.mb-2(
                color="primary"
                block
                :outlined="selectedInvestorType != 'general'"
                @click="setSelectedInvestorType('general')"
                :disabled="saving"
              ) General
              v-btn(
                color="primary"
                block
                :outlined="selectedInvestorType != 'qualified'"
                @click="setSelectedInvestorType('qualified')"
                :disabled="saving"
              ) Qualified

          v-col(cols="12" md="4")
            .font-weight-regular.fs-15.black--text Currency Type
            v-row
              v-col(cols="6")
                v-btn.mb-2(
                  color="primary"
                  block
                  :outlined="selectedCurrencyType != 'TRY'"
                  @click="setSelectedCurrencyType('TRY')"
                  :disabled="saving"
                ) TRY
              v-col(cols="6")
                v-btn(
                  color="primary"
                  block
                  :outlined="selectedCurrencyType != 'USD'"
                  @click="setSelectedCurrencyType('USD')"
                  :disabled="saving"
                ) USD
              v-col(cols="6")
                v-btn.mb-2(
                  color="primary"
                  block
                  :outlined="selectedCurrencyType != 'EUR'"
                  @click="setSelectedCurrencyType('EUR')"
                  :disabled="saving"
                ) EUR
              v-col(cols="6")
                v-btn(
                  color="primary"
                  block
                  :outlined="selectedCurrencyType != 'XAU'"
                  @click="setSelectedCurrencyType('XAU')"
                  :disabled="saving"
                ) XAU      
      v-col(cols="12" md="12")
        .font-weight-regular.fs-15.black--text Risk Profile
        v-col(cols="12" md="12")
          create-portfolio-risk-profile
          create-portfolio-section-info-dateselect

    v-row.mt-4(v-if="formData")
      v-col(cols="12" md="6")
        h2.font-weight-regular.text-center.mb-2 Current Allocation
        div.text-subtitle-2.text-center(v-if="optimization")
          | Created at: 
          b {{ optimization.date }}
        div.text-subtitle-2.text-center(
          v-if="optimization && optimization.cardinality"
        )
          | Cardinality:
          b  {{ optimization.cardinality }}
        div(v-if="!optimization")
          .text-center.my-5
            pie-chart-icon.colors--color12(size="2.7x")
          .text-center.font-weight-regular.colors--color12.fs-20
            | No data
        div(v-else)
          div
            div(v-render-chart="convertWeightData(optimization.allocation)")

      v-col(cols="12" md="6")
        h2.font-weight-regular.text-center.mb-2 New Allocation
        div.text-subtitle-2.text-center(v-if="pendingOptimization")
          | Created at: 
          b {{ pendingOptimization.date }}
        div.text-subtitle-2.text-center(
          v-if="pendingOptimization && pendingOptimization.cardinality"
        )
          | Cardinality:
          b  {{ pendingOptimization.cardinality }}
        div(v-if="!pendingOptimization")
          .text-center.my-5
            pie-chart-icon.colors--color12(size="2.7x")
          .text-center.font-weight-regular.colors--color12.fs-20
            | No data
        div(v-else-if="pendingOptimization.status !== 'finished'")
          .text-center.my-5
            span.fa.fa-spin.fa-spinner.colors--color12.fs-40
          .text-center.font-weight-regular.colors--color12.fs-20
            | Calculating...
        div(v-else-if="pendingOptimization.error")
          .text-center.my-5
            span.fa.fa-times.colors--color12.fs-40
          .text-center.font-weight-regular.colors--color12.fs-20
            | Error
          .text-center.font-weight-regular.colors--color12.fs-12
            | The optimization doesn't have a solution with actual input
            | parameters. Change the parameters and try again.
        div(v-else)
          div
            div(v-render-chart="convertWeightData(pendingOptimization.allocation)")

        div.text-center.my-4
          v-btn(:disabled="saving" color="primary" @click="startCalculation") Calculate
          v-btn.ml-2(:disabled="saving" color="success" v-if="isApprovable" @click="approve") Approve
          v-btn.ml-2(:disabled="saving" color="error" v-if="isDeletable" @click="remove") Remove
          v-btn.ml-2(:disabled="saving" color="secondary" v-if="isEditable" @click="editPendingAllocation") Edit
    v-dialog(v-model="editDialog" width="500")
      v-card
        v-card-title(class="text-h6 grey lighten-2") Edit Allocation
        v-card-text
          v-btn.white--text.float-left.mr-2.mt-4.ml-2(
            color="green"
            @click="setMinimumAllocation()"
            :disabled="!minimum || pendingAllocationSum !== 100 || updatingAllocation"
          ) Set minimum
          div.float-left.mt-4(style="width:150px;")
            v-text-field(v-model.number="minimum" type="number" outlined dense hide-details suffix="%")
            p(v-if="minimumError" class="red--text") value cannot be set
          table
            thead
              tr
                th Symbol
                th Weight
            tbody(v-if="pendingAllocation")
              tr(v-for="item in pendingAllocation")
                th {{ parseSymbol(item.symbol).code }}
                td
                  v-text-field(v-model.number="item.value" type="number" outlined dense hide-details suffix="%")
            tfoot
              tr
                td &nbsp;
                td
                  b(:class="{'red--text': pendingAllocationSum !== 100}") Total: {{pendingAllocationSum}}%
          v-btn.white--text.mt-4(
            color="green"
            @click="saveUpdatedAllocation"
            :disabled="pendingAllocationSum !== 100 || updatingAllocation"
          ) Update
</template>

<script>
import CreatePortfolioSectionTemplate
  from '@/components/create-portfolio/create-portfolio-section-template'
import CreatePortfolioSectionInfoDateselect from
      '@/components/create-portfolio/create-portfolio-section-info-dateselect'
import CreatePortfolioRiskProfile
  from '@/components/create-portfolio/create-portfolio-risk-profile'
import 'c3/c3.css'
import c3 from 'c3/c3.js';
import { PieChartIcon } from 'vue-feather-icons';
import { mapMutations, mapState, mapActions } from "vuex";
import moment from "moment";
import { setWeightsToAMinimum } from "@/lib/utils.js";

export default {
  name: "create-portfolio-info",
  props: {
    sectionIndex: {
      type: Number,
      default: 0
    },
  },

  components: {
    PieChartIcon,
    CreatePortfolioSectionTemplate,
    CreatePortfolioSectionInfoDateselect,
    CreatePortfolioRiskProfile
  },

  data() {
    return {
      editDialog: false,
      pendingAllocation: null,
      minimum: 0,
      minimumError: false,
      updatingAllocation: false,
    }
  },

  computed: {
    ...mapState("OptimizationConfig", [
      "selectedInvestorType",
      "selectedEntityType",
      "selectedCurrencyType",
      "formData",
      "saving"
    ]),

    pendingAllocationSum() {
      return this.pendingAllocation ? this.pendingAllocation.reduce((acc, item) => item.value + acc, 0) : 0;
    },

    isApprovable() {
      return this.pendingOptimization
        && this.pendingOptimization.status === "finished"
        && !this.pendingOptimization.error;
    },

    isDeletable() {
      return this.isApprovable;
    },

    isEditable() {
      return this.pendingOptimization
        && this.pendingOptimization.status === "finished"
        && !this.pendingOptimization.error;
    },

    optimization() {
      if (this.formData && this.formData.optimization) {
        const { optimization } = this.formData;
        const result = optimization?.results[0];
        let error = optimization.error;

        if ( ! result || result.error ) {
          error = true;
        }

        return {
          date: moment(optimization.startedAt).format("DD/MM/YYYY HH:mm"),
          status: optimization.status,
          error,
          weights: result ? result.weights : [],
          allocation: result ? result.allocation : [],
          cardinality: result ? result.cardinality : null
        }
      } else {
        return null;
      }
    },

    pendingOptimization() {
      if (this.formData && this.formData.pendingOptimization) {
        const { pendingOptimization } = this.formData;
        const result = pendingOptimization?.results?.[0];
        let error = pendingOptimization.error;

        if ( ! result || result.error ) {
          error = true;
        }

        return {
          date: moment(pendingOptimization.startedAt).format("DD/MM/YYYY HH:mm"),
          status: pendingOptimization.status,
          error,
          weights: result ? result.weights : [],
          allocation: result ? result.allocation : [],
          cardinality: result ? result.cardinality : null
        }
      } else {
        return null;
      }
    },
  },

  directives: {
    renderChart(bindto, { value: weights }) {
      const obj = Object.fromEntries(weights.map(i => [i.symbol, i.value]));
      let columns = weights.map(i => [i.symbol, Math.abs(i.value).toFixed(4)]);
      const chart = c3.generate({
        bindto,
        size: {
          height: Math.round(300 + columns.length * 2.5),
        },
        donut: {
          label: {
            format: function(value, ratio, id) {
              return Math.round(obj[id] * 10000) / 100 + "%";
            }
          }
        },
        tooltip: {
          format: {
            value: (value, ratio, id) => {
              return Math.round(obj[id] * 10000) / 100 + "%";
            }
          }  
        },
        data: {
          columns,
          type: "donut"
        },
        legend: {
          show: true,
        },
      });
      // TODO: investigate why this is required to draw the chart:
      // (it gets invisible right after initializing)
      setTimeout(() => chart.resize(), 1000);
    }
  },

  methods: {
    ...mapMutations("OptimizationConfig", [
      "setSelectedInvestorType",
      "setSelectedEntityType",
      "setSelectedCurrencyType",
    ]),

    ...mapActions("OptimizationConfig", [
      "calculate",
      "reload",
      "remove",
      "approve",
      "updateAllocation",
    ]),

    setMinimumAllocation() {
      const result = setWeightsToAMinimum(this.pendingAllocation, this.minimum);
      if (!result) {
        this.minimumError = true;
      } else {
        this.pendingAllocation = result;
        this.minimumError = false;
        this.minimum = 0;
      }
    },

    editPendingAllocation() {
      this.pendingAllocation = this.pendingOptimization.allocation
        .map(({ symbol, value }) => ({ symbol, value: Math.round(value * 100) }))
      this.editDialog = true;
    },

    async saveUpdatedAllocation() {
      this.updatingAllocation = true;
      await this.updateAllocation(this.pendingAllocation);
      this.updatingAllocation = false;
      this.editDialog = false;
    },

    convertWeightData(weights = []) {
      return weights
        .filter(i => !!i.value)
        .map(i => {
          return {
            symbol: this.parseSymbol(i.symbol).code,
            value: i.value
          }
        });
    },

    startCalculation() {
      // VALIDATION
      const formData = this.formData;
      const messages = [];

      if (formData.tickers.length < 3) {
        messages.push("Please select at least 3 assets");
      }

      if (formData.objectives[0] === "MaxRet") {
        if ( ! (formData.riskProfile.value > 0) ) {
          messages.push("Risk value has to be greater than 0");
        }
      }

      if (formData.objectives[0] === "MinRisk") {
        if ( ! (formData.target > 0) ) {
          messages.push("Target return has to be greater than 0");
        }
      }

      if (formData.constraints.short) {
        if ( ! (formData.constraints.maxShort > 0) ) {
          messages.push("Max short has to be greater than 0");
        }
      }

      if (formData.constraints.groupConstraints.enabled) {
        if (formData.constraints.groupConstraints.value.length < 1) {
          messages.push("Please create at least 1 group");
        }
      }

      if (formData.constraints.weightConstraints.enabled) {
        if (formData.constraints.weightConstraints.value.length < 1) {
          messages.push("Please add at least 1 weight constraint");
        }
      }

      if (messages.length) {
        messages.forEach(
          message => this.$store.dispatch("toastr", { message })
        );
      } else {
        this.calculate();
      }
    }
  }
}
</script>

<style scoped>
  table {
    margin-top: 20px;
    width: 100%;
  }

  th {
    text-align: left;
    padding: 10px;
    padding-right: 20px;
  }

  tfoot {
    
  }
</style>
