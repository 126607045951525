<template lang="pug">
  div
    label.cp.mb-5
      input(
        type="radio"
        :checked="activeConstraint === 'short'? true : false"
        @click="setEnabledConstraint('short')"
      )
      .text-truncate Short Sale
    v-text-field(
      v-model.number="shortValue"
      outlined
      dense
      height="40px"
      suffix="%"
      label="Max Short"
      :rules="(activeConstraint === 'short'? rules.inputValue_ : [])"
      :disabled="activeConstraint !== 'short'"
    )
</template>

<script>
import { mapMutations, mapState, mapGetters } from "vuex";

export default {
  name: "CreatePortfolioConstraintsShort",

  computed: {
    ...mapState("OptimizationConfig", [
      "formData"
    ]),

    ...mapGetters("OptimizationConfig", [
      "activeConstraint"
    ]),

    shortValue: {
      get() { return this.roundNumber(this.formData.constraints.maxShort * 100, 2) },
      set(val) { this.setMaxShort(this.roundNumber(val / 100, 4)) }
    }
  },

  methods: {
    ...mapMutations("OptimizationConfig", [
      "setEnabledConstraint",
      "setMaxShort"
    ]),
  },

  data() {
    return {
      rules: {
        inputValue_: [
          v => v > 0 || 'Should be greater than 0',
          v => v <= 100 || 'Should be 100 or lesser'
        ],
      },
    }
  }
}
</script>

<style scoped>

</style>