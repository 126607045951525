<template>
  <div>
    <label class="mb-2">
      <input type="checkbox" v-model="cardinalityEnabled" />
      Cardinality constraint
    </label>
    <div class="row">
      <div class="col">
        <v-text-field
          :disabled="!formData.cardinalityCanBeSet || !cardinalityEnabled"
          outlined
          dense
          hide-details
          v-model="cardinalityMinValue"
          prefix="min :"
          />
      </div>
      <div class="col">
        <v-text-field
          :disabled="!formData.cardinalityCanBeSet || !cardinalityEnabled"
          outlined
          dense
          hide-details
          v-model="cardinalityMaxValue"
          prefix="max :"
          />
      </div>
    </div>
    

    <v-alert
      v-if="formData.cardinalityCanBeSet && cardinalityAvailable"
      type="info"
      class="mt-2"
      text>
      <p>
        Calculated value:
        <b class="fs-16">
          {{formData.pendingOptimization.results[0].cardinality}}
        </b>
      </p>
      <p>
        Please enter a number close to the calculated cardinality parameter. If
        the number you enter differs too much from the calculated value,
        optimization might be impossible to solve.
      </p>
      <p>
        Leave empty if you want it to be determined automatically.
      </p>
    </v-alert>
    <v-alert v-if="!formData.cardinalityCanBeSet" text type="warning" class="mt-2">
      An optimization with the same exact configuration must be calculated first
      to be able to set this constraint.
    </v-alert>
  </div>
</template>

<script>
  import { mapMutations, mapState, mapActions } from "vuex";

  export default {
    computed: {
      ...mapState("OptimizationConfig", [
        "formData",
      ]),

      cardinalityEnabled: {
        get() { return this.formData.constraints.cardinality.enabled },
        set(val) { val ? this.enableCardinality() : this.disableCardinality() }
      },

      cardinalityMinValue: {
        get() { return this.formData.constraints.cardinality.value.min },
        set(val) { this.setCardinalityMin(val) }
      },

      cardinalityMaxValue: {
        get() { return this.formData.constraints.cardinality.value.max },
        set(val) { this.setCardinalityMax(val) }
      },

      cardinalityAvailable() {
        if (this.formData.pendingOptimization
          && this.formData.pendingOptimization.results
          && this.formData.pendingOptimization.results.length
          && !this.formData.pendingOptimization.results[0].error
          && this.formData.pendingOptimization.results[0].cardinality) {
          return true;
        }
        else {
          return false;
        }
      }
    },

    methods: {
      configUpdated() {
        this.forbidCardinalityEntry();
      },

      ...mapMutations("OptimizationConfig", [
        "forbidCardinalityEntry",
        "enableCardinality",
        "disableCardinality",
        "setCardinalityMin",
        "setCardinalityMax",
      ]),
    },

    watch: {
      "formData.investorType"(){ this.configUpdated() },
      "formData.trainPeriod"(){ this.configUpdated() },
      "formData.target"(){ this.configUpdated() },
      "formData.objective"(){ this.configUpdated() },
      "formData.tickers"(){ this.configUpdated() },
      "formData.entityType"(){ this.configUpdated() },
      "formData.currencyType"(){ this.configUpdated() },
      "formData.riskProfile.name"(){ this.configUpdated() },
      "formData.riskProfile.value"(){ this.configUpdated() },
      "formData.constraints.noShort"(){ this.configUpdated() },
      "formData.constraints.short"(){ this.configUpdated() },
      "formData.constraints.weight"(){ this.configUpdated() },
      "formData.constraints.maxShort"(){ this.configUpdated() },
      "formData.constraints.views": {
        deep: true,
        handler(){ this.configUpdated() }
      },
      "formData.constraints.groupConstraints": {
        deep: true,
        handler(){ this.configUpdated() }
      },
      "formData.constraints.weightConstraints": {
        deep: true,
        handler(){ this.configUpdated() }
      },
    }
  }
</script>