<template lang="pug">
  create-portfolio-section-template.pb-10(:sectionIndex="sectionIndex" title="Constraints")
    v-row
      v-col(cols="12")
        cardinality
      v-col(cols="12" md="6")
        no-short-sale
      v-col(cols="12" md="6")
        short
    label.cp.mb-4
      input(
        type="radio"
        :checked="activeConstraint === 'weight' ? true : false"
        @click="setEnabledConstraint('weight')"
      )
      | Weight constraint
    v-row
      v-col(cols="12" md="6")
        group
      v-col(cols="12" md="6")
        weight
</template>

<script>
import CreatePortfolioSectionTemplate
  from '@/components/create-portfolio/create-portfolio-section-template'
import NoShortSale
  from '@/components/create-portfolio/constraints/no-short-sale'
import Weight
  from '@/components/create-portfolio/constraints/weight'
import Group from
      '@/components/create-portfolio/constraints/group'
import Short from
      '@/components/create-portfolio/constraints/short'
import Cardinality from
      '@/components/create-portfolio/constraints/cardinality';
import { mapMutations, mapGetters, mapState } from "vuex";

export default {
  name: 'PortfolioCreateSectionConstraintConfiguration',
  props: {
    sectionIndex: {
      type: Number,
      default: 0
    },
  },
  components: {
    Cardinality,
    NoShortSale,
    Weight,
    Group,
    Short,
    CreatePortfolioSectionTemplate,
  },
  methods: {
    ...mapMutations("OptimizationConfig", [
      "setEnabledConstraint",
    ]),
  },
  computed: {
    ...mapGetters("OptimizationConfig", [
      "activeConstraint"
    ]),
    portfolio() {
      if (!this.$store.getters.getPortfolioBySelectedValues?.tickers)
        return true
      else {
        return !(this.$store.getters.getPortfolioBySelectedValues?.tickers.length > 0)
      }
    },
  },
}
</script>

<style scoped lang="sass">
.w-row-2
  background: #00000007
  border: 1px solid #ddd

.input-number[contenteditable]
  border: 1px solid #ddd
  background: #00000007
  font-weight: bold

  &.bg-none
    background: transparent
    border: none

  &:focus
    outline: none
    color: var(--v-primary-base)

  &.min::before
    font-weight: normal
    content: 'min : '

  &.min::after
    font-weight: bold
    content: ' %'

  &.max::before
    font-weight: normal
    content: 'max : '

  &.max::after
    font-weight: bold
    content: ' %'

.apply-all-btn
  background: #00000007
  border: 1px solid #ddd
::v-deep .fa-dot-circle
  color: #0766E4
</style>
