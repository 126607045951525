<template lang="pug">
  v-dialog(:persistent="updatingPassword" v-model="dialog" width="500")
    v-card
      v-card-title(class="text-h5 grey lighten-2") Password Update
      v-card-text
        v-alert.mb-0
          | You are going to be redirected to login page after updating your
          | password.
        v-alert.mt-0(type="error" text v-if="updatePasswordError")
          | {{ updatePasswordError }}
        form(@submit.prevent="update")
          v-text-field.mb-4.mt-7(
            dense
            outlined
            :disabled="updatingPassword"
            v-model.trim="oldPassword"
            label="Old password"
            :type="(show ? 'text': 'password')"
            hide-details
          )
            template(#append v-if="show")
              eye-icon.cp(size="1.3x" @click="show = !show")
            template(#append v-else)
              eye-off-icon.cp(size="1.3x" @click="show = !show")

          v-text-field.mb-2(
            dense
            outlined
            :disabled="updatingPassword"
            v-model.trim="newPassword"
            label="New password"
            :type="(show ? 'text': 'password')"
            hide-details
          )
            template(#append v-if="show")
              eye-icon.cp(size="1.3x" @click="show = !show")
            template(#append v-else)
              eye-off-icon.cp(size="1.3x" @click="show = !show")
          v-progress-linear.mb-4(
            :background-opacity="0.3"
            :color="score.color"
            :value="score.value"
          )

          v-text-field.mb-4(
            dense
            outlined
            :disabled="updatingPassword"
            v-model.trim="newPassword2"
            label="New password (again)"
            :type="(show ? 'text': 'password')"
            hide-details
          )
            template(#append v-if="show")
              eye-icon.cp(size="1.3x" @click="show = !show")
            template(#append v-else)
              eye-off-icon.cp(size="1.3x" @click="show = !show")
      v-divider
      v-card-actions
        v-spacer
        v-btn(:disabled="updatingPassword" color="primary" text @click="update") Update
        v-btn(:disabled="updatingPassword" color="secondary" text @click="dialog = false") Cancel
</template>

<script>
  import { mapMutations, mapActions, mapState } from "vuex";
  import { EyeIcon, EyeOffIcon } from 'vue-feather-icons';
  import zxcvbn from "zxcvbn";

  export default {
    components: {
      EyeIcon,
      EyeOffIcon
    },

    data() {
      return {
        oldPassword: "",
        newPassword: "",
        newPassword2: "",
        show: false,
        passwordUpdated: false
      }
    },

    computed: {
      ...mapState("Auth", [
        "passwordUpdateDialog",
        "updatingPassword",
        "updatePasswordError"
      ]),

      dialog: {
        get() { return this.passwordUpdateDialog },
        set(val) { this.setPasswordUpdateDialog(val) }
      },

      score() {
        const result = zxcvbn(this.newPassword);
        
        switch (result.score) {
          case 4:  return { value: 100, color: "light-blue"  };
          case 3:  return { value: 75,  color: "light-green" };
          case 2:  return { value: 50,  color: "yellow"      };
          case 1:  return { value: 25,  color: "orange"      };
          default: return { value: 0,   color: "red"         };
        }
      }
    },

    methods: {
      ...mapMutations("Auth", [
        "setPasswordUpdateDialog",
        "setUpdatePasswordError",
        "revokeAccessToken",
        "revokeRefreshToken",
        "revoke2faStatus",
      ]),

      ...mapActions("Auth", [
        "updatePassword"
      ]),

      async update() {
        if (this.newPassword !== this.newPassword2) {
          return this.setUpdatePasswordError("Passwords don't match");
        }

        if (this.score.value < 75) {
          return this.setUpdatePasswordError("New password is not strong enough");
        }

        if (this.newPassword === this.oldPassword) {
          return this.setUpdatePasswordError("New password cannot be same with the old one");
        }

        const result = await this.updatePassword({
          oldPassword: this.oldPassword,
          newPassword: this.newPassword
        });

        if (result) {
          this.dialog = false;
          this.oldPassword = "";
          this.newPassword = "";
          this.newPassword2 = "";
          alert("Your password has been updated! Please sign in again.");
          this.revokeAccessToken();
          this.revokeRefreshToken();
          this.revoke2faStatus();
          this.$router.push("/login");
        }
      }
    }
  }
</script>