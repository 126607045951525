<template lang="pug">
  v-card.selectiontemplate.elevation-0.animated.fadeIn.d-flex.flex-column(:class="expand ? 'flex-grow-0' : 'flex-grow-1'")
    v-card-title.selectiontemplate-header.cp.d-flex.align-center.bg-circuit-board.rounded-0(@click="toggleExpand")
      //check-icon.teal.accent-4.white--text.rounded-circle.mr-2.mr-2.py-1(v-if="progressbarValue > 1" size="1.2x")
      //span.selectiontemplate-header-index.mr-2.white--text.font-weight-black.fs-12(v-else)
      span.selectiontemplate-header-index.mr-2.white--text.fs-12.font-weight-black
        | {{sectionIndex}}
      span.text-uppercase.fs-14.colors--color2.font-weight-black {{title}}
      //chevron-up-icon.ml-auto(v-if="expand" size="1.5x")
      //chevron-down-icon.ml-auto(v-else size="1.5x")
    v-card-text.px-md-11.animated.fadeIn.flex-grow-1(v-show="expand" style="position:relative;")
      slot
      //create-portfolio-section-template-footer(
      //  v-if="expand && footerDisplay"
      //  @nextSection="nextSection"
      //)
    v-progress-linear(v-if="progressbarDisplay" :value="(this.progressbarValue ? this.progressbarValue : 1)" color="#0766E4")
</template>

<script>
import {ChevronDownIcon, ChevronUpIcon, CheckIcon} from 'vue-feather-icons'
import CreatePortfolioSectionTemplateFooter
  from '@/components/create-portfolio/create-portfolio-section-template-footer'

export default {
  name: 'CreatePortfolioSectionTemplate',
  components: {
    CreatePortfolioSectionTemplateFooter, ChevronDownIcon, ChevronUpIcon,
    CheckIcon
  },
  props: {
    title: {
      type: String,
    },
    progressbarValue: {
      type: Number,
    },
    sectionIndex: {
      type: Number,
    },
    footerDisplay: {
      type: Boolean,
      default: true
    },
    progressbarDisplay: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    activeSection: {
      set(value) {
        // todo
        //controllers.portfolio.state.createPortfolioActiveSection = this.sectionIndex + value
      },
      get() {
        // todo
        return 1 //controllers.portfolio.state.createPortfolioActiveSection
      }
    }
  },
  data() {
    return {
      expand: true // todo false yapılacak
    }
  },
  created() {
    /*this.sectionVisiable()*/
  },
  /*watch: {
    activeSection() {
      if (this.activeSection === this.activeSection) this.sectionVisiable()
    }
  },*/
  methods: {
    toggleExpand() {
      //this.expand = !this.expand
    },
    /*nextSection() {
      this.activeSection = 1
    },*/
    /*sectionVisiable() {
      this.expand = this.activeSection === this.sectionIndex
    }*/
  }
}
</script>

<style scoped lang="sass">
::v-deep .v-input--checkbox label.v-label
  margin-top: -3px !important

.selectiontemplate
  &-header
    &-index
      background-color: #0766E4
      border-radius: 50%
      width: 25px
      height: 25px
      text-align: center
      line-height: 1.7rem
</style>
