<template lang="pug">
  div
    div(:class="{disabledSection:!formData.constraints.weight}")
      label.cp.mb-5
        input(
          type="checkbox"
          :checked="enabled"
          @click="updateGroupConstraint"
        )
        .text-truncate Weight constraint by asset groups

    div(v-if="enabled && groupValues.length < 1")
      v-alert(text type="error") Add at least 1 group

    div(:class="{disabledSection: !enabled}")
      v-list.pt-0(dense)
        v-list-item(
          color="primary"
          @click="removeGroupItem(i)"
          :input-value="true"
          v-for="(item, i) in groupValues"
          :key="i"
        )
          v-list-item-icon.mr-4
            x-icon.blue--text.text--accent-4(size="1.5x")
          v-list-item-content
            v-list-item-title.d-flex
              span.mr-2.font-weight-regular.fs-14.colors--color16
                | {{ item.group }}
              span.ml-auto.pl-3.font-weight-regular.fs-12.colors--color14
                | tickers:
                span.colors--color16.fs-14  {{item.symbols.length}},
                |  min:
                span.colors--color16.fs-14  {{roundNumber(item.min*100, 2)}}%
                |  max:
                span.colors--color16.fs-14  {{roundNumber(item.max*100, 2)}}%

      div
        v-text-field(outlined dense hide-details v-model="group" label="Group name")
        v-list(dense hide-details multiple :items="symbols")
          v-list-item
            v-checkbox(
              v-model="selectAll_"
              dense
              hide-details
              color="#2962FF"
              label="Select all"
              @change="selectAllTickers($event)"
            )

          v-virtual-scroll(:items="allTickers" item-height="40" max-height="160" bench="5")
            template(v-slot="{ item }")
              v-list-item
                v-checkbox(
                  dense
                  hide-details
                  color="#2962FF"
                  v-model="symbols"
                  :value="item.symbol"
                  :label="parseSymbol(item.symbol).code"
                )

        v-row
          v-col(cols="12" md="4")
            v-text-field(
              :disabled="allTickers.length === symbols.length"
              outlined dense hide-details
              v-model.number="min"
              prefix="min :" suffix="%" type="number"
            )
          v-col(cols="12" md="4")
            v-text-field(
              :disabled="allTickers.length === symbols.length"
              outlined dense hide-details
              v-model.number="max"
              prefix="max :" suffix="%" type="number"
            )

          v-col.text-right(cols="12" sm="4")
            v-btn.mt-1.white--text(
              color="green"
              @click="addConstraint()"
              :disabled="!validForm"
            )
              span.mt-1.fs-14 Add
              plus-icon
</template>

<script>
import { PlusIcon, XIcon } from 'vue-feather-icons'
import { mapMutations, mapGetters, mapState } from "vuex";
import { isValidConstraintNumber } from "../../../lib/utils.js";

export default {
  name: 'CreatePortfolioConstraintsGroup',

  components: {
    PlusIcon,
    XIcon
  },

  data() {
    return {
      group: "",
      symbols: [],
      max: 0,
      min: 0,
      selectAll_: false,
    }
  },

  computed: {
    ...mapState("OptimizationConfig", [
      "formData"
    ]),

    allTickers() {
      return this.formData.tickers;
    },

    groupValues() {
      return this.formData.constraints.groupConstraints.value;
    },

    enabled() {
      return this.formData.constraints.groupConstraints.enabled;
    },

    validForm() {
      return this.group
        && this.symbols.length > 0
        && isValidConstraintNumber(this.max)
        && isValidConstraintNumber(this.min);
    },
  },

  methods: {
    ...mapMutations("OptimizationConfig", [
      "enableGroupConstraint",
      "disableGroupConstraint",
      "addGroupItem",
      "removeGroupItem",
    ]),

    addConstraint() {
      const existSameGroupName = this.groupValues.some(e => this.group === e.group)

      const newSymbols = this.symbols.sort().toString()
      const existSameSymbols = this.groupValues.some(e =>
          newSymbols === e.symbols.sort().toString()
      )

      if (existSameGroupName || existSameSymbols) {
        return this.$store.dispatch("toastr", {
          message: "This group already been added"
        })
      }

      this.addGroupItem({
        group: this.group,
        symbols: this.symbols,
        min: this.roundNumber(this.min/100, 4),
        max: this.roundNumber(this.max/100, 4),
      });
      
      this.group = "";
      this.symbols = [];
      this.min = 0;
      this.max = 0;
      this.selectAll_ = false;
    },

    selectAllTickers(e) {
      this.symbols = e ? this.allTickers.map(i => i.symbol) : [];
      this.min = 100;
      this.max = 100;
    },

    updateGroupConstraint(ev) {
      if (ev.target.checked) {
        this.enableGroupConstraint();
      } else {
        this.disableGroupConstraint();
      }
    }

  }
}
</script>