<template lang="pug">
  v-row
    v-col(cols="12")
      v-dialog(
        v-model="dialog"
        width="500"
      )
        template(v-slot:activator="{ on, attrs }")
          v-btn(
            v-bind="attrs"
            v-on="on"
            color="primary"
            block
            :disabled="!allConfigs"
          ) Transfer
        v-card.white
          v-card-title(lass="text-h5 grey lighten-2") Config Transfer
          v-card-text
            v-container
              v-row(dense)
                v-col(cols="6")
                  v-select(
                    outlined
                    label="Source"
                    full-width
                    dense
                    hide-details
                    :items="sourceOptions"
                    v-model="selectedSource"
                  )
                v-col(cols="6")
                  v-select(
                    outlined
                    label="Target"
                    full-width
                    dense
                    hide-details
                    :items="targetOptions"
                    v-model="selectedTarget"
                  )
                    
          v-divider
          v-card-actions
            v-spacer
            v-btn(
              color="primary"
              text
              @click="close"
            ) Cancel
            v-btn(
              color="primary"
              text
              @click="transferData"
            ) Config Transfer

</template>

<script>
import { mapState } from "vuex";

export default {
  name: "create-portfolio-data-transfer",
  data() {
    return {
      dialog: false,
      selectedSource: null,
      selectedTarget: null,
    };
  },
  computed: {
    ...mapState("OptimizationConfig", ["allConfigs"]),
    sourceOptions() {
      if (!this.allConfigs) return [];
      return this.allConfigs
        .map((config) => {
          return {
            text: `${config.entityType.toUpperCase()} - ${config.investorType.toUpperCase()} -
              ${config.currencyType} - ${config.riskProfile.name.toUpperCase()}`,
            value: {
              investorType: config.investorType,
              riskProfile: config.riskProfile.name,
              entityType: config.entityType,
              currencyType: config.currencyType,
            },
          };
        })
        .sort((a, b) => {
          if (a.value.currencyType < b.value.currencyType) return 1;
          else if (a.value.currencyType > b.value.currencyType) return -1;
          else return 0;
        })
        .sort((a, b) => {
          if (a.value.investorType < b.value.investorType) return -1;
          else if (a.value.investorType > b.value.investorType) return 1;
          else return 0;
        })
        .sort((a, b) => {
          if (a.value.riskProfile < b.value.riskProfile) return -1;
          else if (a.value.riskProfile > b.value.riskProfile) return 1;
          else return 0;
        })
        .sort((a, b) => {
          if (a.value.entityType < b.value.entityType) return -1;
          else if (a.value.entityType > b.value.entityType) return 1;
          else return 0;
        })
        .reverse();
    },
    targetOptions() {
      return this.sourceOptions
        .filter((config) => {
          return !(
            this.selectedSource?.investorType == config.value.investorType &&
            this.selectedSource?.riskProfile == config.value.riskProfile &&
            this.selectedSource?.entityType == config.value.entityType &&
            this.selectedSource?.currencyType == config.value.currencyType
          );
        })
        .sort((a, b) => {
          if (a.value.currencyType < b.value.currencyType) return 1;
          else if (a.value.currencyType > b.value.currencyType) return -1;
          else return 0;
        })
        .sort((a, b) => {
          if (a.value.investorType < b.value.investorType) return -1;
          else if (a.value.investorType > b.value.investorType) return 1;
          else return 0;
        })
        .sort((a, b) => {
          if (a.value.riskProfile < b.value.riskProfile) return -1;
          else if (a.value.riskProfile > b.value.riskProfile) return 1;
          else return 0;
        })
        .sort((a, b) => {
          if (a.value.entityType < b.value.entityType) return -1;
          else if (a.value.entityType > b.value.entityType) return 1;
          else return 0;
        })
        .reverse();
    },
    source() {
      if (!this.selectedSource) return null;
      return this.allConfigs.find(
        (config) =>
          config.investorType === this.selectedSource.investorType &&
          config.riskProfile.name === this.selectedSource.riskProfile &&
          config.entityType === this.selectedSource.entityType &&
          config.currencyType === this.selectedSource.currencyType
      );
    },
    target() {
      if (!this.selectedTarget) return null;
      return this.allConfigs.find(
        (config) =>
          config.investorType === this.selectedTarget.investorType &&
          config.riskProfile.name === this.selectedTarget.riskProfile &&
          config.entityType === this.selectedTarget.entityType &&
          config.currencyType === this.selectedTarget.currencyType
      );
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.selectedSource = null;
      this.selectedTarget = null;
    },
    transferData() {
      this.$store.dispatch("OptimizationConfig/transferData", {
        source: this.selectedSource,
        target: this.selectedTarget,
      });
      this.close();
    },
  },
};
</script>
