<template lang="pug">
  create-portfolio-section-template(
    title="Optimization Goals"
    :sectionIndex="sectionIndex"
  )
    v-row.d-flex.justify-space-between.mb-3
      v-col(cols="12" md="6")
        label.cp
          input(
            v-model="objective" type="radio"
            value="MaxRet"
          )
          .text-truncate Maximize return subject to
        v-text-field.mt-5(
          :disabled="objective !== 'MaxRet'"
          v-model.number="riskValue"
          outlined
          :label="RISK_PROFILE_NAMES[riskProfileName].EN"
          dense
          suffix="%"
          :rules="(this.objective === 'MaxRet'? rules.maxRet_:[])"
        )

      v-col(cols="12" md="6")
        label.cp
          input(
            v-model="objective" type="radio"
            value="MinRisk"
          )
          .text-truncate Minimize risk subject to
        v-text-field.mt-5(
          :disabled="objective !== 'MinRisk'"
          label="Target return"
          v-model.number="target"
          outlined
          dense
          suffix="%"
          :rules="(this.objective === 'MinRisk'? rules.minRisk_ : [])"
        )
</template>

<script>
import CreatePortfolioSectionTemplate from
      '@/components/create-portfolio/create-portfolio-section-template'
import { mapMutations, mapState } from "vuex";

export default {
  name: 'PortfolioCreateSectionGoalConfiguration',

  props: {
    sectionIndex: {
      type: Number,
      default: 0
    },
  },

  components: {
    CreatePortfolioSectionTemplate,
  },

  computed: {
    ...mapState("OptimizationConfig", [
      "formData",
      "RISK_PROFILE_NAMES",
    ]),

    riskValue: {
      get() { return this.roundNumber(this.formData.riskProfile.value * 100, 2) },
      set(val) { this.setRiskProfileValue(this.roundNumber(val / 100, 4)) }
    },

    target: {
      get() { return this.roundNumber(this.formData.target * 100, 2) },
      set(val) { this.setTarget(this.roundNumber(val / 100, 4)) }
    },

    objective: {
      get() { return this.formData.objectives[0] },
      set(val) {
        this.setObjective(val)
        if(val === 'MaxRet') this.setTarget(0)
        else if(val === 'MinRisk') this.setRiskProfileValue(0)
      }
    },

    riskProfileName() {
      return this.formData.riskProfile.name;
    }
  },

  data() {
    return {
      rules: {
        minRisk_: [
          v => v > 0 || 'Should be greater than 0'
        ],
        maxRet_: [
          v => v > 0 || 'Should be greater than 0'
        ],
      },
    }
  },

  methods: {
    ...mapMutations("OptimizationConfig", [
      "setRiskProfileValue",
      "setTarget",
      "setObjective"
    ]),
  }
}
</script>

<style scoped lang="sass">
::v-deep .fa-dot-circle
  color: #0766E4
</style>