<template lang="pug">
  v-row
    v-col(cols="12")
      v-dialog(
        v-model="dialog"
        width="350")
        template(v-slot:activator="{ on, attrs }")
          v-btn(
            v-bind="attrs"
            v-on="on"
            color="primary"
            block
            :disabled="progress"
          ) Copy
            v-progress-circular(v-if="progress" class="ml-1" color="primary"  indeterminate :size="15")
        v-card.white
          v-card-title(lass="text-h5 grey lighten-2") Copy Config To
          v-card-text
            v-container
              v-select(
                    v-if="targetOptions.length"
                    outlined
                    label="Copy To"
                    full-width
                    dense
                    hide-details
                    :items="targetOptions"
                    v-model="selectedTarget"
                  )
       
                    
               
          v-divider
          v-card-actions
            v-spacer
            v-btn(
              color="primary"
              text
              @click="close"
            ) Cancel
            v-btn(
              color="primary"
              text
              @click="transferData"
            ) Copy
  
</template>

<script>
import Vue from "vue";
import mainStore from "../../store/index";

export default {
  name: "create-portfolio-data-transfer",
  data() {
    const instanceLevels = [
      { name: "dev", level: 1 },
      { name: "test", level: 2 },
      { name: "staging", level: 3 },
      { name: "prod", level: 4 },
    ];

    const currentLevel = instanceLevels.find(
      (l) => l.name === process.env.VUE_APP_INSTANCE
    ).level;

    return {
      dialog: false,
      selectedTarget: null,
      progress: false,
      targetOptions: instanceLevels
        .filter((o) => o.level < currentLevel)
        .map((o) => o.name),
    };
  },
  methods: {
    close() {
      this.dialog = false;
      this.selectedTarget = null;
    },
    async transferData() {
      if (this.targetOptions.includes(this.selectedTarget) && !this.progress) {
        try {
          this.progress = true;
          this.dialog = false;
          await Vue.$http.post("/optimization-config/copyTo", {
            target: this.selectedTarget,
          });
          mainStore.dispatch("toastr", {
            message: `Successfully copied to ${this.selectedTarget}`,
            type: "success",
            timeout: 2000,
          });
        } catch (error) {
          console.error(error);

          mainStore.dispatch("toastr", {
            message: `Copied to ${this.selectedTarget} was failed`,
            type: "error",
            timeout: 2000,
          });

          throw error;
        } finally {
          this.progress = false;
          this.selectedTarget = null;
        }
      }
    },
  },
};
</script>
