<template lang="pug">
  v-row
    v-col(cols="")
      v-btn(
        color="primary"
        block
        :outlined="selectedRiskProfile != 'conservative'"
        @click="setSelectedRiskProfile('conservative')"
        :disabled="saving"
      ) Conservative
    v-col.px-md-1(cols="")
      v-btn(
        color="primary"
        block
        :outlined="selectedRiskProfile != 'moderate'"
        @click="setSelectedRiskProfile('moderate')"
        :disabled="saving"
      ) Moderate
    v-col.px-md-1(cols="")
      v-btn(
        color="primary"
        block
        :outlined="selectedRiskProfile != 'growth'"
        @click="setSelectedRiskProfile('growth')"
        :disabled="saving"
      ) Growth
    v-col.px-md-1(cols="")
      v-btn(
        color="primary"
        block
        :outlined="selectedRiskProfile != 'aggressive'"
        @click="setSelectedRiskProfile('aggressive')"
        :disabled="saving"
      ) Aggressive
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "create-portfolio-risk-profile",
  computed: {
    ...mapState("OptimizationConfig", [
      "selectedRiskProfile",
      "saving"
    ])
  },

  methods: {
    ...mapMutations("OptimizationConfig", [
      "setSelectedRiskProfile",
    ])
  }
}
</script>

<style scoped lang="sass">
</style>