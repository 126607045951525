<template lang="pug">
  div.mb-10
    v-fab-transition
      v-btn(elevation="2" fab bottom fixed color="amber lighten-5" right v-if="saving")
        i.fa.fa-spinner.fa-spin

    password-update-dialog

    v-row.mt-1.justify-center
      v-col(cols="11" xl="8")
        div.d-flex.mt-5.mb-2
          a.logo.magnus-logo.mr-1(href="https://getmagnus.com" target="_blank")
          div(v-if="isKt")
            v-img.logo(:src="KT_LOGO" position="0px 0px")
          v-menu(offset-y)
            template(v-slot:activator="{ on, attrs }")
              v-btn.ml-auto(
                fab
                color="grey darken-3 white--text"
                height="51"
                width="51"
                elevation="0"
                v-bind="attrs"
                v-on="on"
              )
                user-icon
            v-list
              v-list-item.cp(dense @click="setPasswordUpdateDialog(true)")
                v-list-item-icon.mr-3
                  key-icon
                v-list-item-title Change password
              v-list-item.cp(dense @click="logout")
                v-list-item-icon.mr-3
                  log-out-icon
                v-list-item-title Sign out
  
        v-row.justify-center
          v-col(cols="12")
            create-portfolio-section-info(
              :sectionIndex="1"
            )
          v-col(cols="12")
            create-portfolio-section-ticker-selection(
              v-if="formData"
              :sectionIndex="2"
            )
          v-col(cols="12")
            create-portfolio-section-estimator-configuration(
              v-if="formData && formData.tickers.length >= 3"
              :sectionIndex="3"
            )
          v-col(cols="12")
            create-portfolio-section-goal-configuration(
              v-if="formData && formData.tickers.length >= 3"
              :sectionIndex="4"
            )
          v-col(cols="12")
            create-portfolio-section-constraint-configuration(
              v-if="formData && formData.tickers.length >= 3"
              :sectionIndex="5"
            )
          //- v-col(cols="12" v-if="false")
          //-   create-portfolio-bottom
</template>

<script>
import MAGNUS_LOGO from '@/assets/image/magnus-logo.svg';
import KT_LOGO from '@/assets/image/kt-logo.png';
import { UserIcon, LogOutIcon, KeyIcon } from 'vue-feather-icons';

import CreatePortfolioSectionInfo
  from '@/components/create-portfolio/create-portfolio-section-info'
import CreatePortfolioSectionTickerSelection from
      '@/components/create-portfolio/create-portfolio-section-ticker-selection'
import CreatePortfolioSectionEstimatorConfiguration
  from '@/components/create-portfolio/create-portfolio-section-estimator-configuration'
import CreatePortfolioSectionGoalConfiguration from
      '@/components/create-portfolio/create-portfolio-section-goal-configuration'
import CreatePortfolioSectionConstraintConfiguration from
      '@/components/create-portfolio/create-portfolio-section-constraint-configuration'

import PasswordUpdateDialog from "@/components/password-update-dialog.vue";

import { mapMutations, mapActions, mapState } from "vuex";

export default {
  name: "CreatePortfolio",

  components: {
    CreatePortfolioSectionInfo,
    CreatePortfolioSectionTickerSelection,
    CreatePortfolioSectionEstimatorConfiguration,
    CreatePortfolioSectionGoalConfiguration,
    CreatePortfolioSectionConstraintConfiguration,
    PasswordUpdateDialog,
    UserIcon,
    LogOutIcon,
    KeyIcon
  },

  data() {
    return {
      updateIntervalId: null,
      saveTimeout: null,
      MAGNUS_LOGO,
      KT_LOGO,
      isKt: process.env.VUE_APP_PROJECT_NAME === "Kuveyt Türk"
    }
  },

  watch: {
    formData: {
      deep: true,
      handler(after, before) {
        const updated = after && before // It was already initialized
          // and this change event is not formData swapping
          && after.investorType === before.investorType
          && after.riskProfile.name === before.riskProfile.name
          && after.entityType === before.entityType
          && after.currencyType === before.currencyType;

        if(after && 
           ((after.constraints.groupConstraints.enabled && !after.constraints.groupConstraints.value.length) ||
            (after.constraints.weightConstraints.enabled && !after.constraints.weightConstraints.value.length)))
            return;

        if (updated) {
          if (this.saveTimeout) {
            clearTimeout(this.saveTimeout);
          }
          this.setSaving(true);
          this.saveTimeout = setTimeout(() => this.save(), 500);
        }
      }
    }
  },

  computed: {
    ...mapState("OptimizationConfig", [
      "formData",
      "loading",
      "saving"
    ]),

    ...mapState("Tickers", [
      "selectedTickers",
    ]),

    isReadyToSave() {
      return !this.loading && this.formData;
    }
  },

  async created() {
    await this.init();
    this.updateIntervalId = setInterval(() => this.reload(), 2000);
    let self = this;
    window.onbeforeunload = function() {
      if (self.saving) {
        return "Changes you made may not be saved?"
      }
    }
  },

  methods: {
    ...mapActions("OptimizationConfig", [
      "init",
      "reload",
      "save"
    ]),
    
    ...mapMutations("OptimizationConfig", [
      "setSaving"
    ]),

    ...mapActions("Tickers", [
      "loadEntities"
    ]),

    ...mapMutations("Auth", [
      "revokeAccessToken",
      "revokeRefreshToken",
      "revoke2faStatus",
      "setPasswordUpdateDialog"
    ]),

    logout() {
      this.revokeAccessToken();
      this.revokeRefreshToken();
      this.revoke2faStatus();
      this.$router.push("/login");
    }
  }
}
</script>

<style scoped>
  .logo {
    width: 50px;
    height: 50px;
  }
  .magnus-logo {
    background-color: #1e2d59;
    background-image: url('~@/assets/image/magnus-logo-2.svg');
    background-size: auto 32px;
    background-position: 8px 12px;
    border-radius: 50%;
  }
</style>