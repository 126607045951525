<template lang="pug">
  create-portfolio-section-template(
    title="Black Litterman Model"
    :sectionIndex="sectionIndex"
  )
    v-row
      // todo Bunun için python tarafında dataprovider düzenlemesi sonrası deactive yapılabilir olacak
      //v-col(cols="12")
      //  v-checkbox(
      //    hide-details
      //    v-model="viewsEnabled"
      //    color="#0766E4"
      //    :ripple="false"
      //    label="Views"
      //  )
      v-col(cols="12")
        v-row
          // todo Bunun için python tarafında dataprovider düzenlemesi sonrası deactive yapılabilir olacak
          //v-col(:class="{disabledSection: !viewsEnabled}" cols="5")
          v-col(:class="{disabledSection: !viewsEnabled}" cols="5")
            v-select(
              background-color="#1976d2"
              :items="tickerOptions"
              color="white"
              v-model="position"
              solo
            )
              template(#label)
                .white--text Select asset
              template(#append)
                .white--text
                  chevron-down-icon

            v-select(
              background-color="#1976d2"
              :items="viewTypes"
              v-model="view"
              solo
            )
              template(#append)
                .white--text
                  chevron-down-icon

            v-select(
              background-color="#1976d2"
              :items="tickerOptions.filter(item => item.value != position)"
              v-model="relative"
              v-if="view ==='will outperform'"
              solo
            )
              template(#label)
                .white--text Select asset
              template(#append)
                .white--text
                  chevron-down-icon

            v-text-field(
              label="Return"
              v-model.number="return_"
              :rules="rules.return_"
              outlined
              dense
            )
              template(#append)
                percent-icon(size="1.3x")

            v-text-field.confidence-input(
              label="Confidence"
              v-model.number="confidence"
              outlined
              dense
            )
              template(#append)
                v-menu(bottom)
                  template(v-slot:activator="{ on, attrs }")
                    v-btn(color="black" text dark v-bind="attrs" v-on="on")
                      chevron-down-icon(size="2.0x")
                  v-list(dense max-height="300px")
                    v-list-item.cp(
                      v-for="(item, index) in confidencePercents"
                      :key="index"
                      dense
                      @click="confidence= item"
                    )
                      v-list-item-content
                        v-list-item-title %{{item}}
                percent-icon(size="1.3x" style="margin-top:6px")

            v-btn.white--text.float-right(
              color="green"
              @click="addItem"
              :disabled="!validForm"
            )
              span.mt-1.fs-14 Add
              plus-icon

          v-col(cols="7")
            v-row
              v-col(v-if="viewsValue.length" cols="12")
                .d-flex.align-center.justify-end.error--text.cp.mt-n6(
                  @click="removeAllViewItem"
                )
                  .font-weight-regular.fs-14 Remove all
                  chevrons-left-icon.ml-1(size="1x")
                v-list.pt-0(dense)
                  v-virtual-scroll(
                    :items="viewsValue"
                    item-height="40"
                    height="408"
                    bench="5"
                  )
                    template(v-slot="{ item, index }")
                      v-list-item.cp(
                        color="primary"
                        :ripple="true"
                        @click="removeViewItem(index)"
                        :input-value="true"
                      )
                        v-list-item-icon.mr-4
                          x-icon.blue--text.text--accent-4(size="1.5x")
                        v-list-item-content
                          v-list-item-title.d-flex
                            span.mr-2.font-weight-regular.fs-14.colors--color16
                              | [{{parseSymbol(item.position).code}}]
                            span.mr-2.text-truncate.font-weight-regular.fs-14.colors--color14
                              | {{ !item.relative ? 'will return' : 'will outperform' }}
                            span.mr-2.text-truncate.colors--color16.font-weight-regular.fs-14
                              | {{ !item.relative ? '' : ("[" + parseSymbol(item.relative).code + "]") }}
                            span.ml-auto.pl-3.font-weight-regular.fs-12.colors--color14
                              | return:
                              span.colors--color16.fs-14  {{roundNumber(item.return*100, 2)}}%, 
                              | confidence:
                              span.colors--color16.fs-14  {{roundNumber(item.confidence*100, 2)}}%
</template>
<script>
import CreatePortfolioSectionTemplate
  from '@/components/create-portfolio/create-portfolio-section-template'
import {
  PercentIcon,
  ChevronDownIcon,
  PlusIcon,
  XIcon,ChevronsLeftIcon
} from 'vue-feather-icons';

import { mapState, mapMutations } from "vuex";

export default {
  name: 'CreatePortfolioEstimatorsBlackLitterman',
  components: {
    CreatePortfolioSectionTemplate,
    PercentIcon,
    ChevronDownIcon,
    XIcon,
    PlusIcon, ChevronsLeftIcon
  },
  props: {
    sectionIndex: {
      type: Number,
      default: 0
    },
  },
  computed: {
    ...mapState("OptimizationConfig", [
      "formData"
    ]),

    viewsEnabled: {
      get() { return this.formData.constraints.views.enabled },
      set(value) { this.setViewsEnabled(value) }
    },

    viewsValue() {
      return this.formData.constraints.views.value;
    },

    validForm() {
      let valid = this.view === "will outperform" ? this.relative : true;
      return valid && this.position && this.return_ && this.confidence;
    },

    tickerOptions() {
      return this.formData.tickers.map(i => {
        return {
          text: i.code,
          value: i.symbol
        }
      });
    }
  },
  data() {
    return {
      viewTypes: ["will return", "will outperform"],
      view: "will return",

      position: null,
      relative: null,
      return_: 0,
      confidence: 0,

      confidencePercents: [25, 50, 75, 100],
      rules: {
        return_: [
          v => v > 0 || 'Shoud be greater than 0'
        ],
      },
    }
  },

  methods: {
    ...mapMutations("OptimizationConfig", [
      "setViewsEnabled",
      "addViewItem",
      "removeViewItem",
      "removeAllViewItem",
    ]),

    addItem() {
      const entries = [
        ["position", this.position],
        ["relative", this.relative],
        ["return", this.roundNumber(this.return_ / 100, 4)],
        ["confidence", this.roundNumber(this.confidence / 100, 4)]
      ];

      const newKey = entries.map(i => i[1]).join("-");
      const exists = this.viewsValue.find(
        i => newKey === [i.position, i.relative, i.return, i.confidence].join("-")
      );

      if (exists) {
        return this.$store.dispatch("toastr", {
          message: "This view's already been added"
        });
      }

      this.addViewItem(Object.fromEntries(entries));
    },
  },
}
</script>
<style lang="sass" scoped>
  ::v-deep .v-select__selection--comma
    color: white
</style>

<style>
  .confidence-input.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner {
    margin-top: 2px;
  }
</style>