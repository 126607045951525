<template lang="pug">
  div
    label.cp.mb-5
      input(
        type="radio"
        :checked="activeConstraint === 'noShort'? true : false"
        @click="setEnabledConstraint('noShort')"
      )
      .text-truncate No Short Sale
</template>

<script>
import { mapMutations, mapState, mapGetters } from "vuex";

export default {
  name: 'CreatePortfolioConstraintsNoShortSale',
  computed: {
    ...mapState("OptimizationConfig", [
      "formData"
    ]),

    ...mapGetters("OptimizationConfig", [
      "activeConstraint"
    ]),
  },

  methods: {
    ...mapMutations("OptimizationConfig", [
      "setEnabledConstraint",
    ])
  }
}
</script>
<style lang="sass" scoped></style>
